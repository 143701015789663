import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HTTPUtility } from '../utils/http-utility';
import {
  Headers,
  Http,
  Request,
  RequestMethod,
  RequestOptions,
  Response,
  ResponseContentType,
  ResponseOptions
} from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class WialonApiService {
  hostUrl: any = "https://wialonapp.herokuapp.com/https://hst-api.wialon.com/wialon/ajax.html?";
  postionList: any = [];
  // This is trial key. Original key is yet to be provided by NVE.


  constructor(public htpp: HttpClient, private httpUtil: HTTPUtility, private https: Http) { }


  getAllUnit(): Promise<any> {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"avl_unit",
      "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', getunit, 'HOME', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }


  getSID(): Promise<any> {

    if (typeof (Storage) !== "undefined") {
      // Store

      return Promise.resolve(sessionStorage.getItem('sid'));
    }
    return null;

    // const jwt = '"a4fd021e27166bae068090746adc4c2b921817DE065A3D3B281CF6C034489C8DDB6DF2EF"';
    // const siteURL: any = this.hostUrl + "svc=token/login&params={\"token\":" + jwt + "}";
    // return this.httpUtil.sendRequest('Post', siteURL, null).then((res) => {
    //   return Promise.resolve<any>(res.json().eid);
    // }).catch((err) => {

    //   return Promise.reject('');
    // });


  }
  getAvlGroupDetails() {
    return this.getSID().then((res) => {
      const groupURL: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"avl_unit_group",
            "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', groupURL, 'HOME', null, true).then(resp => {

        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('Error Occured While Fetching Group Details');
      });
    }).catch((err) => {
      return Promise.reject<any>('Invalid Session Id');
    });
  }

  getResourceDetails() {
    return this.getSID().then((res) => {
      const resourceURL: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"avl_resource",
            "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', resourceURL, 'HOME', null, true).then(resp => {

        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('Error Occure While Fetching Group Details');
      });
    }).catch((err) => {
      return Promise.reject<any>('Invalid Session Id');
    });
  }
  // create the fuel report





  getReportTripDontDelete(resourceID, tempID) {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=report/get_report_data&sid=` + res + `&params={"itemId":` + resourceID + `,"col":[` + tempID + `],"flags":0}`;
      return this.httpUtil.sendRequest('Post', getunit, 'Fuel', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }



  constructRequest(unitDetails, messageContent, access_token) {
    let requestList = []
    unitDetails.forEach(unit => {
      let str = `{"svc":"unit/exec_cmd","params":{"itemId":"${unit.unitID}","commandName":"__app__chatterbox_msg_virt","linkType":"vrt","param":"${messageContent}","timeout":0,"flags":0}}`
      requestList.push(str);
    });

    return this.getSID().then((res) => {

      let formData: FormData = new FormData();
      formData.set('params', `{"params":[${requestList.toString()}],"flags":0}`);
      formData.set('sid', res)


      return Promise.resolve<any>(formData);
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }


  getAllUser(): Promise<any> {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=core/search_items&sid=` + res + `&params={"spec":{"itemsType":"user",
      "propName":"sys_name","propValueMask":"*","sortType":"","propType":""},"force":1,"flags":4611686018427387903,"from":0,"to":0}`;
      return this.httpUtil.sendRequest('Post', getunit, 'HOME', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }


  getDuplicate(): Promise<any> {
    return this.getSID().then((res) => {
      const getunit: any = this.hostUrl + `svc=core/duplicate&sid=` + res + `&params={"operateAs":"","continueCurrentSession":true,"checkService":"","restore":1,"appName":""}`;
      return this.httpUtil.sendRequest('Post', getunit, 'HOME', null, true).then(resp => {
        return Promise.resolve<any>(resp.json());
      }).catch((err) => {
        return Promise.reject<any>('');
      });
    }).catch((err) => {
      return Promise.reject<any>('');
    });
  }
  getSummary(stats: string[][]) {
    let result = [];
    stats.forEach(elem => {
      let obj = { name: elem[0], sumValue: elem[1] }
      result.push(obj);
    });
    return result;
  }
  getTableData(header: string[], tableRes: Tableresult[]) {
    let tabledata = [];
    let latlan =  0
    tableRes.forEach((table: Tableresult,index) => {
      latlan = 0;
      if(index!=(tableRes.length-1)){
          latlan = this.getDistance({lat:table.c[header.indexOf('Coordinates')].y,lng:table.c[header.indexOf('Coordinates')].x},
         {lat:tableRes[index+1].c[header.indexOf('Coordinates')].y,lng:tableRes[index+1].c[header.indexOf('Coordinates')].x})
         

         
      }
      tabledata.push({
        logTime: table.c[header.indexOf('Time')],
        unitSpeed: table.c[header.indexOf('Speed')],
        latlan: latlan,
        location: table.c[header.indexOf('Location')],
        ignitionStatus: table.c[header.indexOf('io_239')]
      })
    });
    return tabledata;

  }
  constructLogReport(res: RootObject) {
    let result = {
      summary: {
        totalIdle: 0,
        timeIdle: '',
        totalStop: 0,
        timeParking: '',
        totalRunning: 0,
        timeRunning: '',
        timeTotal: '',
        totalKm: 0,
        totalTime: 0
      },
      chartData: {
        total: 0,
        idle: 0,
        running: 0,
        stop: 0
      }, tabledata: []
    };

    result.tabledata = this.getTableData(res.header[1].reportResult.tables[0].header,
      res.tableresult);

    let startFresh = true;
    let rowData;
    let data = [];
    for (let index = 0; index < result.tabledata.length; index++) {
      let speed = parseInt(String(result.tabledata[index].unitSpeed).split(" ")[0]);
     
      let currentStatus = result.tabledata[index].ignitionStatus == '1'
        && speed == 0 ? 'Idle' : result.tabledata[index].ignitionStatus == '0' 
        &&   speed >= 0 ? 'Parking' : 'Running';
        
      if (startFresh) {
        startFresh = false;
        rowData = {
          beginTime: result.tabledata[index].logTime.t,
          startAddress: result.tabledata[index].location.t,
          startlat: result.tabledata[index].location.y,
          startlan: result.tabledata[index].location.x,
          endTime: '',
          endLat: '',
          endlan: '',
          endAddress: '',
          status: currentStatus,
          duration: 0,
          mileage: result.tabledata[index].latlan,
          inMinutes: 0,
          inSeconds: 0,
          startTimeUnix: result.tabledata[index].logTime.v
        }
      }

      if (currentStatus === rowData.status) {
        rowData.mileage+=result.tabledata[index].latlan;
      } else {
        let diff = Math.round((result.tabledata[index].logTime.v - rowData.startTimeUnix) / 60);
        rowData.endTime = result.tabledata[index].logTime.t,
          rowData.endAddress = result.tabledata[index].location.t,
          rowData.endLat = result.tabledata[index].location.y,
          rowData.endlan = result.tabledata[index].location.x,
          rowData.duration = this.timeDiff(diff)
          rowData.inMinutes = diff
          rowData.inSeconds = result.tabledata[index].logTime.v - rowData.startTimeUnix
          index = index - 1;

        startFresh = true;


        data.push(rowData)
      }

      if (result.tabledata.length - 1 == index && rowData.endTime == '') {
        let diff = Math.round((result.tabledata[index].logTime.v - rowData.startTimeUnix) / 60);
        rowData.endTime = result.tabledata[index].logTime.t,
          rowData.endAddress = result.tabledata[index].location.t,
          rowData.endLat = result.tabledata[index].location.y,
          rowData.endlan = result.tabledata[index].location.x,
          rowData.inMinutes = diff
          rowData.inSeconds = result.tabledata[index].logTime.v - rowData.startTimeUnix

          // if(diff<=2 && rowData.status=='Idle'){
          //   rowData.status = 'Running';
          // }else if(diff>=2 &&  rowData.status=='Idle' && rowData.mileage>=1){ 
          //   rowData.status = 'Running';
          // } 
  
   
          if(rowData.status=='Idle' || rowData.status=='Parking'){
           // rowData.mileage = 0
          }
        //  rowData.mileage = this.getDistance({lat:rowData.endLat,lng:rowData.endlan},{lat:rowData.startlat,lng:rowData.startlan});

        rowData.duration = this.timeDiff(diff)
        startFresh = true;
        data.push(rowData)
      }
    }
    // summary: {
    //   totalIdle: 0,
    //   avgIdle: 0,
    //   totalStop: 0,
    //   avgStop: 0,
    //   totalRunning: 0,
    //   avgRunning: 0,
    //   totalKM: 0
    // },
    // chartData: {
    //   total: 0,
    //   idle: 0,
    //   running: 0,
    //   stop: 0
    // }
    data.forEach((element) => {

      if(element.status=='Parking'){
        result.chartData.stop=result.chartData.stop+1
        result.summary.totalStop= result.summary.totalStop  + element.inSeconds
        element.mileage = 0;
      }else if(element.status=='Idle'){
        result.chartData.idle=result.chartData.idle+1
        result.summary.totalIdle=  result.summary.totalIdle  + element.inSeconds

        if(element.mileage > 0.50){
        //  result.summary.totalKm += element.mileage;
        }
        element.mileage = 0;
      }else{
        result.summary.totalKm = result.summary.totalKm + element.mileage;
        result.summary.totalRunning= result.summary.totalRunning + element.inSeconds
        result.chartData.running= result.chartData.running+1
      
      }
    
 

    });
    result.summary.totalRunning = result.summary.totalRunning/60
    result.summary.totalStop = result.summary.totalStop/60

    result.summary.totalIdle = result.summary.totalIdle/60

    result.summary.timeRunning =  this.timeDiff(result.summary.totalRunning) 
    result.summary.timeParking = this.timeDiff(result.summary.totalStop) 
    result.summary.timeIdle = this.timeDiff(result.summary.totalIdle) 
    result.summary.timeTotal = this.timeDiff(result.summary.totalRunning + result.summary.totalStop + result.summary.totalIdle) 
    result.summary.totalTime = result.summary.totalRunning + result.summary.totalStop + result.summary.totalIdle;
    
    result.tabledata = data;
    return result;
  }
  timeDiff(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hr " + rminutes + " min.";
  }
  genrateReprot(fromDate, toDate, unitID, userID, templateID) {

    return this.getSID().then((res) => {

      const reportTable: any = `https://cors-anywhere.herokuapp.com/https://hst-api.wialon.com/wialon/ajax.html?svc=core/batch&params={"params":[{"svc":"report/cleanup_result","params":{}},{"svc":"report/exec_report","params":{"reportResourceId":${userID},"reportTemplateId":${templateID},"reportTemplate":null,"reportObjectId":${unitID},"reportObjectSecId":0,"interval":{"flags":16777216,"from":${fromDate},"to":${toDate}},"reportObjectIdList":[]}}],"flags":0}&sid=${res}`
      return this.httpUtil.sendRequest('Post', reportTable).then((reportHeaderRes) => {
           const reportTable: any = 'https://cors-anywhere.herokuapp.com/https://hst-api.wialon.com/wialon/ajax.html?svc=report/select_result_rows&sid=' + res + '&params={"tableIndex":0,"config":{"type":"range","data":{"from":0,"to":' + reportHeaderRes.json()[1].reportResult.tables[0].rows + ',"level":1}}}'
        return this.httpUtil.sendRequest('Post', reportTable).then((reportTableResult) => {
          let result: any = { 'header': reportHeaderRes.json(), 'tableresult': reportTableResult.json() };

          result = this.constructLogReport(result)
          return Promise.resolve<any>(result);
        }).catch((err) => {
          console.log(err)

        });
      }).catch((err) => {
        console.log(err)
      });

    }).catch((err) => {

    });
  }

 rad(x) {
    return x * Math.PI / 180;
  };
  
  getDistance (p1, p2) {

    var R = 6378137; // Earth’s mean radius in meter
    var dLat = this.rad(p2.lat - p1.lat);
    var dLong = this.rad(p2.lng - p1.lng);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return  Math.fround(d/1000); // returns the distance in meter
  }
}




export interface Grouping {
}

export interface TotalRaw {
  v: number;
  vt: number;
}

export interface Table {
  name: string;
  label: string;
  grouping: Grouping;
  flags: number;
  rows: number;
  level: number;
  columns: number;
  header: string[];
  header_type: string[];
  total: string[];
  totalRaw: TotalRaw[];
}

export interface ReportResult {
  msgsRendered: number;
  stats: string[][];
  tables: Table[];
  attachments: any[];
}

export interface Header {
  error: number;
  reportResult: ReportResult;
}

export interface Tableresult {
  n: number;
  i1: number;
  i2: number;
  t1: number;
  t2: number;
  d: number;
  c: any[];
}

export interface RootObject {
  header: Header[];
  tableresult: Tableresult[];
}


